<template>
  <div>
    <Header />
    <main class="p-4 py-20 min-h-screen max-w-6xl mx-auto">
      <h1 class="font-bold uppercase text-brandGrey text-2xl mb-4">
        The Legal Stuff
      </h1>
      <div class="space-y-4">
        <p>
          numbers_game does not promote gambling in any way. We do not claim to
          make our users money or encourage them to place bets.
        </p>

        <p>
          Our goal is to collate relevant data and present this in a digestible
          format to the masses.
        </p>

        <p>
          We provide odds as we believe it could be of interest to a subset of
          our users, not to encourage anybody to use this information in a
          meaningful way. Please Gamble Responsibly.
        </p>
      </div>
      <h1 class="font-bold uppercase text-brandGrey text-2xl mb-4 mt-8" id="termsandconditions">
        Terms and Conditions
      </h1>
      <div class="space-y-4">
        <p>
          In these terms, "we", "our" and "numbers_game" are Numbers Game Ltd (a company registered in Alderney with registered company number 13604663 and registered office at 120 Timber Wharf 32 Worsley Street, Manchester, M15 4NX).<br><br>
          Please read the terms carefully and ensure you understand them fully. By using our services or products (the "Services") (whether on our websites, tablet and/ or mobile application, (the "numbers_game package(s)")) you will be deemed by us to have understood and accepted these terms and agree to be bound by them. From time to time, certain provisions of the terms will change and be updated accordingly. Each change will become effective and you will be deemed to have accepted such changes, immediately upon such change being posted and notified to you. If you do not accept any change, please discontinue your use of the numbers_game package(s).
        </p>
        <h2 class="font-bold text-brandGrey text-xl mb-4">
          Under 18s
        </h2>
        <p>
          No one under the age of 18 is permitted to use the numbers_game package(s) in conjunction with placing bets. If you are aged under 18, please do not use the numbers_game package(s) for these purposes.
        </p>

        <h2 class="font-bold text-brandGrey text-xl mb-4">
          Accounts
        </h2>
        <p>
        Individuals are permitted to open an account with us in order to access more features across the numbers_game package(s), and receive tailored content and services.<br><br>
        At any time, you may choose to cancel and end your subscription by selecting that option within our ‘Manage Membership’ page.
        </p>

        <h2 class="font-bold text-brandGrey text-xl mb-4">
          numbers_game Service
        </h2>
        <p>
          Our Services allow you to use software on our system to browse football match predictions based on weighted averages from various prediction models.<br><br>
          Our Services and the numbers_game Platforms employ proprietary technology to allow you to retrieve, view and maintain information all within one convenient service.<br><br>
          When you click on any odds on numbers_game  you are shown odds from Third Party Bookmakers for comparison purposes only. For further information or to place bets, please visit individual Bookmakers websites.<br><br>
          You acknowledge and agree that when you are accessing and retrieving information relating to Third Party Bookmakers sites, we are not acting as your agents nor the agents on behalf of the Third Party Bookmakers but that we are simply displaying said odds for comparison purposes.
        </p>

        <h2 class="font-bold text-brandGrey text-xl mb-4">
          Bet Placement
        </h2>
        <p>
          numbers_game receives odds directly from Third Party Bookmakers, using the latest technology to ensure publishing as close to real-time as possible. All of the odds shown remain the property and responsibility of the Third Party Bookmakers themselves. All odds data is shown in good faith and numbers_game does not guarantee its availability or accuracy.<br><br>numbers_game does not accept liability for errors in prices, odds, incorrectly calculated winnings, events or markets shown on the numbers_game platforms or odds changes prior to a bet being placed and will not be liable for any loss suffered by any party if the service is unavailable or inaccurate in any way.<br><br>
          If you have any queries relating to a bet, including any requests to void a bet, you should contact the Third Party Bookmaker directly.<br><br>
          Terms and conditions vary between Third Party Bookmakers and apply to any bet placed with that Third Party Bookmaker.
        </p>

        <h2 class="font-bold text-brandGrey text-xl mb-4">
          General liability
        </h2>
        <p>
          You accept that Services are provided to you "as is" with any faults or failings and any representation, warranty, condition or guarantee whatsoever, express or implied (including, without limitation, any implied warranty of accuracy, completeness, uninterrupted provision, quality, merchantability, fitness for a particular purpose or non-infringement) excluded to the fullest extent permitted by law.<br><br>
          Under no circumstances (including, without limitation, negligence) are we liable for any injury, loss, claim, loss of data, income, profit or opportunity, loss or damage to property, general damages or any direct, indirect, special, incidental, consequential, exemplary or punitive damages of any kind whatsoever arising out of or in connection with your access to, or use of, or inability to use, our Services, any software, any materials or other information on the numbers_game Platforms for any goods, materials or services available therefrom (whether based in contract, tort and whether negligent or otherwise), even if we have has been advised of the possibility of such damages or loss or that such loss was foreseeable.<br><br>
          You specifically acknowledge, agree and accept that we are not liable to you for any of the following:-<br><br>
            • any loss whatsoever arising from the use, abuse or misuse of your account or any of our products and services<br>
            • any loss incurred in transmitting information by the internet or by e-mail<br>
            • any incorrect bets being placed<br>
            • technical faults with Third Party Bookmaker APIs or websites<br>
            • any technical failures, system breakdowns, defects, delays, interruptions, manipulated or improper data transmission, loss or corruption of data or communications' lines failure or distributed denial of service<br>
            • attacks, viruses or any other adverse technological consequence of your choosing to use our products and services<br>
            • the accuracy, completeness or currency of any information services provided<br>
            • any failure on our part to interact with you where we may have concerns about your activities.<br>
          <br>
          Nothing in these terms shall operate so as to exclude or restrict our liability for death or personal injury caused by our negligence.<br><br>
          If any term of these Terms & Conditions is or becomes illegal, invalid or unenforceable, that will not affect the legality, validity or enforceability of any other term of these Terms & Conditions.<br><br>
          These Terms & Conditions shall be governed and construed in accordance with the Laws of England and Wales and You irrevocably agree to submit to the exclusive jurisdiction of the English Courts.
        </p>
      </div>
    </main>

    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  name: "Disclaimer",
  components: {
    Header,
    Footer,
  },
};
</script>

<style></style>
